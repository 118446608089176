import { Button, Dialog, DialogTrigger, Popover, DisclosureGroup } from 'react-aria-components'
import { Radio, RadioGroup } from 'rsuite'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Icon from '@/components/Icon'
import { useCurrentAccount } from '@/modules/session/auth.store'
import { useFeatureFlags } from '@/modules/session/global.hook'
import Loader from '@/components/Loader'
import { EventChannelType, FeatureType } from '@/api/videobot.schemas'

const TypeFilter = ({ channel, onChannelChange }) => {
	const activeAccount = useCurrentAccount()
	const { data: featureFlags } = useFeatureFlags({ account_id: activeAccount?.id })
	const [translate] = useTranslation(['analytics'])

	if (!featureFlags) {
		return <Loader />
	}

	const eventChannelTypes = Object.entries(EventChannelType)
		.filter(
			([_, value]) =>
				value !== 'slider' ||
				featureFlags?.includes(`dashboard:${FeatureType.new_widgets}`),
		)
		.map(([label, value]) => ({
			label: translate(label),
			value,
		}))

	return (
		<RadioGroup className="flex flex-col gap-2 py-2" value={channel} onChange={onChannelChange}>
			{eventChannelTypes.map(({ label, value }) => (
				<Radio key={value} value={value}>
					{label}
				</Radio>
			))}
		</RadioGroup>
	)
}

export const AnalyticsTypeFilter = ({ onFilterApply, channel: defaultChannel }) => {
	const [channel, setChannel] = useState(defaultChannel)
	const [isOpen, setOpen] = useState(false)
	const popOverTriggerRef = useRef(null)
	const [translate] = useTranslation(['analytics'])

	const handleFilter = (newChannel) => {
		onFilterApply({
			channel: newChannel,
		})
		setChannel(newChannel)
	}

	return (
		<DialogTrigger>
			<Button
				className="h-full min-w-64"
				onPress={() => setOpen((prev) => !prev)}
				ref={popOverTriggerRef}
			>
				<div className="flex items-center rounded border border-line p-3">
					<span>Type: {translate(channel)}</span>
					<div className="ml-auto fill-muted">
						<Icon name="chevron-down" />
					</div>
				</div>
			</Button>

			<Popover triggerRef={popOverTriggerRef} isOpen={isOpen} onOpenChange={setOpen}>
				<Dialog className="min-w-64 rounded border border-line bg-body px-4 py-2">
					<DisclosureGroup className="analytics-filter flex flex-col gap-2 divide-y divide-line">
						<TypeFilter channel={channel} onChannelChange={handleFilter} />
					</DisclosureGroup>
				</Dialog>
			</Popover>
		</DialogTrigger>
	)
}
