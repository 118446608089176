import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'

import Button from '@/components/Button'
import FieldSelect from '@/components/FieldSelect'
import FieldText from '@/components/FieldText'
import FormField from '@/components/FormField'
import { Col, Row } from '@/components/Grid'
import Loader from '@/components/Loader'
import {
	ShareVideobotActions,
	ShareVideobotConfigWrapper,
	ShareVideobotIframePreviewWrapper,
	ShareVideobotSection,
} from '@/features/Videobot/components/ShareVideobot/ShareVideobot.styles'
import { useShareModal } from '@/features/Videobot/hooks/useShareModal'
import Error from '@/layout/Error'
import { BotStatusType, BotThumbnailType, SlideCategoryType } from '@/api/videobot.schemas'
import { useReadBotById, useReadBotWidgetById } from '@/api/videobot'
import { useVideobotEditor } from '@/features/Videobot/hooks/videobot_editor'
import { ControlledFieldToggle } from '@/components/field_toggle/field_toggle.component'
import FieldAvatar from '@/components/FieldAvatar'
import { useCurrentAccount } from '@/modules/session/auth.store'

import { VideobotIframe } from './VideobotIframe'
import { LANGUAGES, createEmbedUrl } from './sharedOptions'

const ShareVideobotIframePresentation = () => {
	const currentAccount = useCurrentAccount()
	const [t] = useTranslation(['videobot'])
	const {
		register,
		control,
		setValue,
		formState: { errors, isSubmitting },
		watch,
		getValues,
	} = useVideobotEditor()
	const { setModalContent, openModal } = useShareModal()

	const videobot = getValues()
	const watchSlides = watch('slides')
	const watchWidth = watch('iframeSettings.width')
	const watchHeight = watch('iframeSettings.height')
	const watchThumbnailType = watch('iframeSettings.thumbnail.thumbnailType')

	const hasIntroSlide =
		Boolean(watchSlides?.length) &&
		watchSlides.some((slide) => slide.category === SlideCategoryType.INTRO)

	const introSlide =
		videobot.slides?.find((slide) => slide.category === SlideCategoryType.INTRO) ||
		videobot.slides?.[0]

	const defaultThumbnailSlide = videobot.iframeSettings?.thumbnail?.slide ?? introSlide?.id
	const thumbnailSlide = videobot.slides?.find(
		(slide) => slide.id === videobot.iframeSettings.thumbnail?.slide,
	)

	const maxTimestamp = thumbnailSlide?.duration ?? introSlide?.duration ?? 0

	const lang = videobot.iframeSettings?.language || 'en'
	const url = createEmbedUrl(videobot.publicId, { lang })

	const embedCode = `<iframe src="${url}" frameborder="0" height="${watchHeight}" width="${watchWidth}" style="border-radius: 10px;" scrolling="no" allow="clipboard-write;web-share"></iframe>`

	useEffect(() => {
		setModalContent({
			title: t('videobot:sharePage.embedIframe'),
			code: embedCode,
		})
	}, [embedCode])

	const handleShowClick = async (event) => {
		event.preventDefault()
		if (!hasIntroSlide) {
			toast.warning(t('videobot:sharePage.noSlides'))
			return
		}
		openModal(true)
	}

	return (
		<React.Fragment>
			<ShareVideobotConfigWrapper>
				<div className="flex flex-1 flex-col overflow-auto">
					<ShareVideobotSection>
						<h4>{t('videobot:sharePage.options')}</h4>

						<Row>
							<Col>
								<FormField label={t('videobot:sharePage.language')}>
									<FieldSelect
										register={register}
										placeholder={LANGUAGES[0].name}
										name="iframeSettings.language"
										type="text"
										error={errors.iframeSettings?.language}
										options={LANGUAGES.map((item) => ({
											value: item.id,
											label: item.name,
											item,
										}))}
										setValue={setValue}
										size="large"
										defaultValue={videobot.iframeSettings?.language || 'en'}
										required
									/>
								</FormField>
							</Col>
						</Row>

						<Row>
							<Col sm={6} xxs={12}>
								<FormField label={t('videobot:sharePage.height')}>
									<FieldText
										register={register}
										placeholder="0"
										name="iframeSettings.height"
										type="number"
										error={errors.iframeSettings?.height}
										size="large"
										required
									/>
								</FormField>
							</Col>

							<Col sm={6} xxs={12}>
								<FormField label={t('videobot:sharePage.width')}>
									<FieldText
										register={register}
										placeholder="0"
										name="iframeSettings.width"
										type="number"
										error={errors.iframeSettings?.width}
										size="large"
										required
									/>
								</FormField>
							</Col>
						</Row>
					</ShareVideobotSection>

					<ShareVideobotSection>
						<h4>{t('videobot:sharePage.thumbnail')}</h4>

						<Row>
							<Col>
								<FormField label={t('videobot:sharePage.thumbnailType')}>
									<FieldSelect
										register={register}
										name="iframeSettings.thumbnail.thumbnailType"
										error={errors.iframeSettings?.thumbnail?.thumbnailType}
										options={Object.values(BotThumbnailType).map((item) => ({
											value: item,
											label: t(`videobot:sharePage.thumbnailTypes.${item}`),
											item,
										}))}
										setValue={setValue}
										defaultValue={
											videobot.iframeSettings?.thumbnail?.thumbnailType ||
											BotThumbnailType.FRAME
										}
										size="large"
									/>
								</FormField>
							</Col>
						</Row>

						{watchThumbnailType === BotThumbnailType.FRAME && (
							<Row>
								<Col sm={6} xxs={12}>
									<FormField label={t('videobot:sharePage.slide')}>
										<FieldSelect
											register={register}
											placeholder={introSlide?.name ?? ''}
											name="iframeSettings.thumbnail.slide"
											error={errors.iframeSettings?.thumbnail?.slide}
											options={videobot.slides?.map((item) => ({
												value: item.id,
												label: item.name,
												item,
											}))}
											setValue={setValue}
											defaultValue={defaultThumbnailSlide}
											size="large"
										/>
									</FormField>
								</Col>

								<Col sm={6} xxs={12}>
									<FormField label={t('videobot:sharePage.timestamp')}>
										<FieldText
											register={register}
											placeholder="0"
											name="iframeSettings.thumbnail.time"
											type="number"
											error={errors.iframeSettings?.thumbnail?.time}
											rules={{
												valueAsNumber: true,
												min: {
													value: 0,
													message: t('validation:min', { value: 0 }),
												},
												max: {
													value: maxTimestamp,
													message: t('validation:max', {
														value: maxTimestamp,
													}),
												},
											}}
											size="large"
											defaultValue={
												videobot.iframeSettings?.thumbnail?.time || 0
											}
										/>
									</FormField>
								</Col>
							</Row>
						)}

						{watchThumbnailType === BotThumbnailType.CUSTOM && (
							<Row>
								<Col>
									<FieldAvatar
										name="iframeThumbnailId"
										type="image"
										mediaType="CoverImage"
										valueName="iframeThumbnailId"
										setValue={setValue}
										defaultValue={videobot.iframeThumbnail?.url}
										accountId={currentAccount.id}
										label={t('videobot:sharePage.customThumbnail')}
										isCover
									/>
								</Col>
							</Row>
						)}
					</ShareVideobotSection>
					<ShareVideobotSection>
						<h4>{t('videobot:sharePage.functionality')}</h4>
						<Row>
							<Col>
								<FormField label={t('videobot:sharePage.autoplayOnScroll')}>
									<ControlledFieldToggle
										control={control}
										name="iframeSettings.autoplayOnScroll"
									/>
								</FormField>
							</Col>
						</Row>
					</ShareVideobotSection>
				</div>

				<ShareVideobotActions>
					<Button
						onClick={handleShowClick}
						disabled={videobot.status !== BotStatusType.Published || isSubmitting}
						iconPrepend="code"
					>
						{t('videobot:sharePage.showCode')}
					</Button>
				</ShareVideobotActions>
			</ShareVideobotConfigWrapper>

			<ShareVideobotIframePreviewWrapper>
				<VideobotIframe
					src="/preview/current?preview=true"
					videobot={videobot}
					style={{ width: `${watchWidth}px`, height: `${watchHeight}px` }}
				/>
			</ShareVideobotIframePreviewWrapper>
		</React.Fragment>
	)
}

export const ShareVideobotIframe = () => {
	const { id } = useParams()
	const { data: videobot, isError: isErrorVideobot } = useReadBotById(id)
	const { data: videobotEmbed, isError: isErrorVideobotEmbed } = useReadBotWidgetById(id)

	const isLoading = !videobot || !videobotEmbed
	const isError = isErrorVideobot || isErrorVideobotEmbed

	return (
		<React.Fragment>
			<ShareVideobotIframePresentation />
			{isLoading && <Loader cover isLoading />}
			{isError && <Error background="body" />}
		</React.Fragment>
	)
}
