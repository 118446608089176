import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from '@/components/Button'
import FieldSelect from '@/components/FieldSelect'
import FieldText from '@/components/FieldText'
import FormField from '@/components/FormField'
import Modal from '@/components/Modal'
import PropTypes from '@/lib/propTypes'
import { useCurrentAccount, useCurrentUser } from '@/modules/session/auth.store'

const InviteUserModal = ({ onSubmit, onClose, show, isLoading, noAccount = false }) => {
	const { t } = useTranslation(['account'])
	const activeAccount = useCurrentAccount()
	const user = useCurrentUser()
	const formRef = useRef(null)
	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors, isValid },
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			userEmail: '',
			role: 'Editor',
		},
	})
	const userEmail = getValues('userEmail')

	const ROLES = [
		{
			name: 'Admin',
			label: 'Admin',
			show: user.isSuperuser || ['Owner', 'Admin', 'Reseller'].includes(activeAccount.role),
		},
		{
			name: 'Editor',
			label: 'Editor',
			show: user.isSuperuser || ['Owner', 'Admin', 'Reseller'].includes(activeAccount.role),
		},
		{
			name: 'Viewer',
			label: 'Viewer',
			show: user.isSuperuser || ['Owner', 'Admin', 'Reseller'].includes(activeAccount.role),
		},
	]

	const ModalHeader = <h3>{t('user:inviteUser')}</h3>

	return (
		<Modal header={ModalHeader} show={show} onClose={onClose}>
			<form
				autoComplete="off"
				ref={formRef}
				onSubmit={handleSubmit((data) => onSubmit(data))}
			>
				<FormField>
					<FieldText
						register={register}
						placeholder={t('account:fields.email.placeholder')}
						name="userEmail"
						type="email"
						error={errors.userEmail}
						required
						size="large"
					/>
				</FormField>
				{!noAccount && (
					<FormField>
						<FieldSelect
							register={register}
							placeholder={t('account:fields.role.placeholder')}
							name="role"
							type="text"
							required
							options={ROLES.filter((o) => o.show).map((item) => ({
								value: item.name,
								label: item.label,
								item,
							}))}
							setValue={setValue}
							size="large"
							defaultValue="Editor"
						/>
					</FormField>
				)}
				<Button disabled={!userEmail || !isValid} block isLoading={isLoading}>
					{t('user:sendInvite')}
				</Button>
			</form>
		</Modal>
	)
}

InviteUserModal.propTypes = {
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
	show: PropTypes.bool,
	isLoading: PropTypes.bool,
}

export default InviteUserModal
