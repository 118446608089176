import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import SVG from 'react-inlinesvg'
import { useParams, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

import { InfoIconWrapper } from '@/components/InfoIcon/InfoIcon.styles'
import Loader from '@/components/Loader'
import { AnalyticsList } from '@/features/analytics/components/AnalyticsList'
import { AnalyticsSummary } from '@/features/analytics/components/AnalyticsSummary'
import {
	useReadAccountDailyTotalAnalytics,
	useReadAccountDetailById,
	useReadAccountTotalAnalytics,
	useReadAllBots,
	useReadAnalyticReport,
	useReadPaginatedWidgetAnalytics,
	useReadUsersForAccountById,
} from '@/api/videobot'
import { EventChannelType, RoleType } from '@/api/videobot.schemas'
import { RoleCheck } from '@/modules/session/auth.component'
import { useCurrentAccount } from '@/modules/session/auth.store'
import { AnalyticsSliderList } from '@/features/analytics/components/AnalyticsSliderList'
import { AnalyticsChart } from '@/features/analytics/components/analytics_chart'

const AnalyticsReportWrapper = styled.div`
	-webkit-print-color-adjust: exact !important;
	print-color-adjust: exact !important;

	@media screen {
		padding: 5%;
	}

	@media print {
		padding: 10mm;
	}

	${InfoIconWrapper} {
		display: none;
	}

	.rdt_TableRow,
	.rdt_TableHeadRow {
		> *:first-child {
			display: none;
		}
		> *:last-child {
			display: none;
		}
	}
`

const AnalyticsReportHeader = styled.div`
	margin-bottom: 2.5rem;

	h1 {
		margin-bottom: 2.5rem;
	}

	> svg {
		height: 1.25rem;
		display: block;
		width: auto;
		margin-bottom: 2.5rem;
	}
`
const AnalyticsReportDetails = styled.div`
	display: flex;
	font-size: 0.9rem;

	dl {
		min-width: 10rem;
		max-width: 30%;

		&:not(:last-child) {
			margin-right: 2rem;
			padding-right: 2rem;
			border-right: 2px solid ${(props) => props.theme.color.line};
		}

		dd {
			margin-bottom: 0;
		}
	}
`

const AnalyticsReportContents = () => {
	const { t } = useTranslation(['analytics'])
	const [searchParams] = useSearchParams()
	const { channel } = useParams()
	const params = {
		from_date: searchParams.get('from_date'),
		to_date: searchParams.get('to_date'),
		bot_ids: searchParams.get('bot_ids'),
		user_ids: searchParams.get('user_ids'),
		slider_ids: searchParams.get('slider_ids'),
		size: 9999,
		channel,
	}
	const fromDate = new Date(params.from_date)
	const hasSessions = fromDate >= new Date('2023-07-03') // FIXME: Remove this when users won't fetch old analytics
	const isSliderAnalytics = params.channel === EventChannelType.slider
	const activeAccount = useCurrentAccount()
	const { data: accountData, isSuccess: isAccountSuccess } = useReadAccountDetailById(
		activeAccount.id,
	)
	const {
		data: reportData,
		isLoading: isReportLoading,
		isSuccess: isReportSuccess,
	} = useReadAnalyticReport(
		{
			account_id: activeAccount.id,
			...params,
		},
		{ query: { enabled: !isSliderAnalytics } },
	)
	const {
		data: totalData,
		isLoading: isTotalLoading,
		isSuccess: isTotalSuccess,
	} = useReadAccountTotalAnalytics(activeAccount.id, {
		...params,
	})
	const {
		data: botsData,
		isLoading: isBotsLoading,
		isSuccess: isBotsSuccess,
	} = useReadAllBots({ account_id: activeAccount.id })
	const {
		data: usersData,
		isLoading: isUsersLoading,
		isSuccess: isUsersSuccess,
	} = useReadUsersForAccountById(
		activeAccount.id,
		{ page: 1, size: 1000 },
		{ query: { enabled: activeAccount.role !== 'Editor' } },
	)
	const {
		data: sliderData,
		isLoading: isSliderLoading,
		isSuccess: isSliderSuccess,
	} = useReadPaginatedWidgetAnalytics(
		activeAccount.id,
		{ ...params },
		{ query: { retry: false, staleTime: 60000, enabled: isSliderAnalytics } },
	)
	const { data: chartData, isSuccess: isChartSuccess } = useReadAccountDailyTotalAnalytics(
		activeAccount.id,
		params,
	)

	const userSuccess = isUsersSuccess || activeAccount.role === 'Editor'
	const userLoading = isUsersLoading && activeAccount.role !== 'Editor'

	const isReportAnalyticsSuccess =
		(!isSliderAnalytics && isReportSuccess) || (isSliderAnalytics && isSliderSuccess)

	const isReportAnalyticsLoading =
		(!isSliderAnalytics && isReportLoading) || (isSliderAnalytics && isSliderLoading)

	useEffect(() => {
		const body = document.querySelector('body')
		body.style.overflow = 'auto'
		body.style.position = 'static'
	}, [])

	useEffect(() => {
		if (
			isAccountSuccess &&
			isTotalSuccess &&
			isReportAnalyticsSuccess &&
			isBotsSuccess &&
			userSuccess &&
			isChartSuccess
		) {
			setTimeout(() => {
				window.parent.postMessage(
					{
						action: 'REPORT_READY',
					},
					'*',
				)
			}, 250)
		}
	}, [
		isAccountSuccess,
		isTotalSuccess,
		isReportAnalyticsSuccess,
		isBotsSuccess,
		isUsersSuccess,
		userSuccess,
		isSliderAnalytics,
		isChartSuccess,
	])

	return (
		<React.Fragment>
			<AnalyticsReportWrapper>
				<AnalyticsReportHeader>
					<SVG src={'/assets/img/logo-full.svg'}></SVG>
					<h1>{t('analytics')}</h1>
					<AnalyticsReportDetails>
						<dl>
							<dt>{t('dateRange')}</dt>
							<dd>
								{params.from_date} - {params.to_date}
							</dd>
						</dl>
						<dl>
							<dt>{t('videobots')}</dt>
							<dd>
								{params.bot_ids
									? botsData?.records
											?.filter((o) => params.bot_ids.includes(o.id))
											.map((o) => o.name)
											.join(', ')
									: t('all')}
							</dd>
						</dl>
						{usersData && (
							<dl>
								<dt>{t('users')}</dt>
								<dd>
									{params.user_ids
										? usersData
												?.filter((o) => params.user_ids.includes(o.id))
												.map((o) => `${o.firstName} ${o.lastName}`)
												.join(', ')
										: t('all')}
								</dd>
							</dl>
						)}
					</AnalyticsReportDetails>
				</AnalyticsReportHeader>
				{chartData && (
					<AnalyticsChart
						channel={params.channel}
						fromDate={params.from_date}
						toDate={params.to_date}
						userIds={params.user_ids}
						sliderIds={params.slider_ids}
						botIds={params.bot_ids}
					/>
				)}
				{accountData && totalData && (
					<AnalyticsSummary
						data={totalData}
						report
						channel={channel}
						analyticsDefaultChannel={accountData.analyticsDefaultChannel}
						analyticsDisplayFields={accountData.analyticsDisplayFields}
						hasSessions={hasSessions}
					/>
				)}
				{accountData && reportData && !isSliderAnalytics && (
					<AnalyticsList
						data={reportData}
						report
						channel={channel}
						analyticsDisplayFields={accountData.analyticsDisplayFields}
						params={params}
						hasSessions={hasSessions}
					/>
				)}
				{accountData && sliderData && isSliderAnalytics && (
					<AnalyticsSliderList
						params={params}
						analyticsDisplayFields={accountData.analyticsDisplayFields}
						reportData={sliderData}
					/>
				)}
			</AnalyticsReportWrapper>

			{(isReportAnalyticsLoading ||
				isTotalLoading ||
				isBotsLoading ||
				userLoading ||
				!accountData) && <Loader cover />}
		</React.Fragment>
	)
}

export const AnalyticsReportPage = () => {
	return (
		<RoleCheck
			roles={[
				RoleType.Super_Admin,
				RoleType.Reseller,
				RoleType.Owner,
				RoleType.Editor,
				RoleType.Viewer,
				RoleType.Admin,
			]}
		>
			<AnalyticsReportContents />
		</RoleCheck>
	)
}
