import {
	Button,
	Dialog,
	DialogTrigger,
	Popover,
	DisclosureGroup,
	Disclosure,
	Heading,
	DisclosurePanel,
} from 'react-aria-components'
import { Checkbox, CheckboxGroup, Input, InputGroup } from 'rsuite'
import { Fragment, useRef, useState } from 'react'
import { capitalize, union, difference, intersection } from 'lodash-es'
import { FormProvider, useForm, useFormContext, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Icon from '@/components/Icon'
import { BotLiveStatusType, BotStatusType } from '@/api/videobot.schemas'
import { useReadAllTags } from '@/api/videobot'
import { useCurrentAccount } from '@/modules/session/auth.store'
import Loader from '@/components/Loader'

const FilterDisclosure = ({ id, title, subTitle, children }) => {
	return (
		<Disclosure className="py-3" id={id}>
			{({ isExpanded }) => (
				<Fragment>
					<Heading className="m-0 flex flex-col gap-3">
						<span className="m-0 font-sans text-base font-semibold">{title}</span>
						<Button className="flex w-full items-center" slot="trigger">
							<span className="max-w-48 overflow-hidden text-ellipsis whitespace-nowrap font-sans text-sm font-normal text-muted">
								{subTitle}
							</span>
							<div className="ml-auto fill-muted">
								<Icon name={isExpanded ? 'chevron-up' : 'chevron-down'} />
							</div>
						</Button>
					</Heading>
					<DisclosurePanel className="py-2 text-sm">{children}</DisclosurePanel>
				</Fragment>
			)}
		</Disclosure>
	)
}

const StatusFilter = () => {
	const { setValue, watch, control } = useFormContext()
	const watchStatus = watch('status')
	const [translate] = useTranslation(['analytics'])

	const publishedStatus = [
		BotLiveStatusType.live,
		BotLiveStatusType.offline,
		BotLiveStatusType.inactive,
	]

	const publishedCheckAll = (_, checked) => {
		if (checked) {
			setValue('status', union(watchStatus, publishedStatus))
		} else {
			setValue('status', difference(watchStatus, publishedStatus))
		}
	}

	return (
		<FilterDisclosure
			id="status"
			title="Status"
			subTitle={watchStatus.length > 0 ? watchStatus.map(capitalize).join(', ') : 'Status'}
		>
			<div className="flex flex-col rounded-sm border border-line">
				<Checkbox
					value="published"
					indeterminate={
						difference(publishedStatus, watchStatus).length > 0 &&
						intersection(publishedStatus, watchStatus).length > 0
					}
					checked={difference(publishedStatus, watchStatus).length === 0}
					onChange={publishedCheckAll}
				>
					Published
				</Checkbox>

				<Controller
					control={control}
					name="status"
					render={({ field: { onChange, value, ref } }) => (
						<CheckboxGroup
							className="flex flex-col"
							onChange={onChange}
							value={value}
							ref={ref}
						>
							<div className="flex flex-col pl-4">
								{publishedStatus.map((status) => (
									<Checkbox value={status} key={status}>
										{translate(`analyticsStatus.${status}`)}
									</Checkbox>
								))}
							</div>

							<Checkbox value={BotStatusType.Draft}>Draft</Checkbox>
							<Checkbox value={BotStatusType.Deactivated}>Deactivated</Checkbox>
						</CheckboxGroup>
					)}
				/>
			</div>
		</FilterDisclosure>
	)
}

const TagFilter = () => {
	const currentAccount = useCurrentAccount()
	const { watch, control } = useFormContext()
	const watchTags = watch('tag_ids')
	const [search, setSearch] = useState('')
	const { data: tags } = useReadAllTags({ account_id: currentAccount?.id, size: 100 })

	if (!tags) {
		return (
			<div>
				<Loader />
			</div>
		)
	}

	const filteredTags = tags.records.filter((tag) =>
		tag.name.toLowerCase().includes(search.toLowerCase()),
	)

	const noTags = filteredTags.length === 0

	return (
		<FilterDisclosure
			id="tag"
			title="Tags"
			subTitle={
				watchTags.length > 0
					? watchTags
							.map((tagId) => tags.records.find((tag) => `${tag.id}` === tagId)?.name)
							.filter(Boolean)
							.join(', ')
					: 'Tags'
			}
		>
			<div className="flex flex-col rounded-sm border border-line">
				<div className="p-2">
					<InputGroup inside className="max-w-52 !rounded-sm">
						<InputGroup.Addon>
							<Icon name="search" className="size-4" />
						</InputGroup.Addon>
						<Input
							placeholder="Search"
							onChange={(value) => setSearch(value)}
							value={search}
						/>
					</InputGroup>
				</div>

				<Controller
					control={control}
					name="tag_ids"
					render={({ field: { onChange, value, ref } }) => (
						<CheckboxGroup
							className="flex flex-col"
							onChange={onChange}
							value={value}
							ref={ref}
						>
							<div className="flex flex-col">
								{filteredTags.map(({ id, name }) => (
									<Checkbox value={`${id}`} key={id}>
										{name}
									</Checkbox>
								))}
							</div>
						</CheckboxGroup>
					)}
				/>

				{noTags && <span className="p-2 text-muted">No tags found.</span>}
			</div>
		</FilterDisclosure>
	)
}

export const AnalyticsAdvancedFilters = ({ onFilterApply, status, tagIds }) => {
	const form = useForm({
		defaultValues: {
			status: status || null,
			tag_ids: tagIds || [],
		},
	})
	const watchStatus = form.watch('status')
	const watchTagIds = form.watch('tag_ids')
	const isClearable = Boolean(watchStatus?.length > 0 || watchTagIds?.length > 0)
	const [isOpen, setOpen] = useState(false)
	const popOverTriggerRef = useRef(null)
	const filterBadge = watchStatus.length + watchTagIds.length

	const handleFilter = (data) => {
		onFilterApply({
			status: data.status.join(','),
			tag_ids: data.tag_ids.join(','),
		})

		setOpen(false)
	}

	const resetFilter = () => {
		form.setValue('status', [])
		form.setValue('tag_ids', [])

		onFilterApply({
			status: null,
			tag_ids: null,
		})
	}

	return (
		<DialogTrigger>
			<Button
				className="h-full min-w-64"
				onPress={() => setOpen((prev) => !prev)}
				ref={popOverTriggerRef}
			>
				<div className="flex items-center rounded border border-line p-3">
					<span>Filters</span>
					{filterBadge > 0 && (
						<span className="ml-2 flex size-5 items-center justify-center rounded-full bg-global text-body">
							{filterBadge}
						</span>
					)}
					<div className="ml-auto fill-muted">
						<Icon name="chevron-down" />
					</div>
				</div>
			</Button>

			<Popover triggerRef={popOverTriggerRef} isOpen={isOpen} onOpenChange={setOpen}>
				<Dialog className="min-w-64 rounded border border-line bg-body px-4 py-2">
					<form onSubmit={form.handleSubmit(handleFilter)}>
						<FormProvider {...form}>
							<DisclosureGroup className="analytics-filter flex flex-col gap-2 divide-y divide-line">
								<StatusFilter />
								<TagFilter />

								<div className="flex py-3 text-base">
									<button
										type="button"
										className={'text-red disabled:text-muted'}
										disabled={!isClearable}
										onClick={() => resetFilter()}
									>
										Clear filters
									</button>
									<button type="submit" className="ml-auto">
										Apply
									</button>
								</div>
							</DisclosureGroup>
						</FormProvider>
					</form>
				</Dialog>
			</Popover>
		</DialogTrigger>
	)
}
