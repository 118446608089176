import { AnimatePresence, motion } from 'framer-motion'
import type { DefaultTFuncReturn } from 'i18next'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import SVG from 'react-inlinesvg'

import { FetchError } from '@/fetcher'

import { ErrorContent, ErrorIcon, ErrorWrapper } from './Error.styles'

const SERVER_ERROR_IMAGE = '/assets/img/error-500.svg'

interface ErrorProps {
	title?: DefaultTFuncReturn | string
	img?: string
	actions?: React.ReactNode
	error?: FetchError<unknown>
	message?: string
}

export const Error: React.FC<ErrorProps> = ({ title, img, actions, error, message }) => {
	const { t } = useTranslation('errors')

	const errorIcon = useMemo(() => {
		if (img) return <SVG src={img} />
		if (error instanceof FetchError && error.statusCode >= 500) {
			return <SVG src={SERVER_ERROR_IMAGE} />
		}
		return <p className="mb-0 text-6xl text-muted">:(</p>
	}, [img, error])

	return (
		<AnimatePresence mode="wait">
			<ErrorWrapper
				as={motion.div}
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 0.25 }}
			>
				<ErrorContent
					as={motion.div}
					initial={{ opacity: 0, y: -25 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{
						duration: 0.95,
						ease: [0.16, 1, 0.3, 1],
						delay: 0.3,
					}}
				>
					<ErrorIcon>{errorIcon}</ErrorIcon>
					<h2>{title || t('errors:errorOccured')}</h2>
					{error?.message && <p>{error.message}</p>}
					{message && <p>{message}</p>}
					{actions}
				</ErrorContent>
			</ErrorWrapper>
		</AnimatePresence>
	)
}

export default Error
