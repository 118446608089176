import Chart from 'react-apexcharts'
import type { Props as ApexChartProps } from 'react-apexcharts'
import { format } from 'date-fns'
import { renderToStaticMarkup } from 'react-dom/server'

import type { ReadAccountDailyTotalAnalyticsQueryResult } from '@/api/videobot'
import { useReadAccountDailyTotalAnalytics } from '@/api/videobot'
import type { ReadAccountDailyTotalAnalyticsParams } from '@/api/videobot.schemas'
import { EventChannelType } from '@/api/videobot.schemas'
import { useCurrentAccountStrict } from '@/modules/session/auth.store'
import theme from '@/lib/theme'

interface AnalyticsChartProps {
	channel: EventChannelType
	fromDate: string
	toDate: string
	botIds: string
	sliderIds: string
	userIds: string
	chartData?: any
}

const transformChartData =
	({ channel }: { channel: EventChannelType }) =>
	(data: ReadAccountDailyTotalAnalyticsQueryResult): ApexChartProps => {
		const labels = data.map((day) => format(new Date(day.day), 'd MMM yyyy'))
		const options: ApexChartProps['options'] = {
			chart: {
				type: 'area',
				height: 420,
				zoom: {
					enabled: false,
				},
				animations: {
					enabled: false,
				},
				toolbar: {
					show: false,
				},
			},
			grid: {
				padding: {
					left: 32,
					right: 0,
				},
			},
			legend: {
				show: false,
			},
			stroke: {
				curve: 'smooth',
				width: 2,
			},
			dataLabels: {
				enabled: false,
			},
			labels,
			yaxis: {
				show: false,
			},
			xaxis: {
				labels: {
					rotate: 0,
				},
				tickPlacement: 'on',
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: true,
				},
				tooltip: {
					enabled: false,
				},
				crosshairs: {
					stroke: {
						color: theme.color.primary,
						width: 1,
						dashArray: 0,
					},
				},
			},
			responsive: [
				{
					breakpoint: 768,
					options: {
						xaxis: {
							tickAmount: 2,
						},
						grid: {
							padding: {
								left: 0,
								right: 0,
							},
						},
					},
				},
			],
			fill: {
				type: 'gradient',
				gradient: {
					shadeIntensity: 0.33,
					opacityFrom: 0.75,
					opacityTo: 0.0,
					stops: [0, 90, 100],
					colorStops: [],
				},
			},
			tooltip: {
				custom({ series, dataPointIndex, w }) {
					const sessionSeriesIndex = w.config.series.findIndex(
						(s: any) => s.name === 'Session',
					)
					const openedSeriesIndex = w.config.series.findIndex(
						(s: any) => s.name === 'Opened',
					)

					const sessionSeries = series[sessionSeriesIndex]
					const openedSeries = series[openedSeriesIndex]

					const ctr = sessionSeries[dataPointIndex]
						? (openedSeries[dataPointIndex] / sessionSeries[dataPointIndex]) * 100
						: 0

					const el = (
						<div className="flex flex-col gap-4 p-3">
							<h4 className="m-0 px-3 text-base font-normal">
								{labels[dataPointIndex]}
							</h4>
							<dl className="m-0 flex flex-col gap-2 rounded border border-line px-4 py-3">
								{series.map((s: number[], index: number) => (
									<div
										key={w.config.series[index].name}
										className="flex items-center gap-2"
									>
										<dt className="m-0 flex items-center gap-1 text-muted">
											<div
												style={{
													['--chart-markup-color' as any]:
														w.config.series[index].color,
												}}
												className="size-3 rounded-full bg-[var(--chart-markup-color)]"
											/>
											{w.config.series[index].name}:
										</dt>
										<dd className="m-0">{s[dataPointIndex]}</dd>
									</div>
								))}

								<div className="flex items-center gap-2">
									<dt className="m-0 flex items-center gap-1 text-muted">
										<div className="">%</div>
										CTR:
									</dt>
									<dd className="m-0">{ctr.toFixed(1)}</dd>
								</div>
							</dl>
						</div>
					)
					return renderToStaticMarkup(el)
				},
			},
		}

		return {
			options,
			series: [
				{
					name: 'Session',
					data: data.map((day) =>
						channel === EventChannelType.slider
							? (day.analytics.sliderSession ?? 0)
							: day.analytics.session,
					),
					color: theme.color.primary,
				},
				{
					name: 'Opened',
					data: data.map((day) => day.analytics.opened),
					color: theme.color.purpleDark,
				},
			],
		}
	}

export const AnalyticsChart = ({
	channel,
	fromDate,
	toDate,
	botIds,
	sliderIds,
	userIds,
}: AnalyticsChartProps) => {
	const activeAccount = useCurrentAccountStrict()
	const queryParams: ReadAccountDailyTotalAnalyticsParams = {
		channel,
		from_date: fromDate,
		to_date: toDate,
		bot_ids: botIds,
		slider_ids: sliderIds,
		user_ids: userIds,
	}
	const { data, error } = useReadAccountDailyTotalAnalytics(activeAccount.id, queryParams, {
		query: {
			select: transformChartData({ channel }),
		},
	})

	if (error) {
		return (
			<section>
				<p>Cannot load analytics chart.</p>
				<p>{error.message}</p>
			</section>
		)
	}

	if (!data) {
		return null
	}

	return (
		<div>
			<Chart height={420} type="area" options={data.options} series={data.series} />
		</div>
	)
}
