import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { Controller } from 'react-hook-form'
import { DatePicker } from 'rsuite'

import Button from '@/components/Button'
import FieldAvatar from '@/components/FieldAvatar'
import FieldColor from '@/components/FieldColor'
import FieldSelect from '@/components/FieldSelect'
import FieldText from '@/components/FieldText'
import FormField from '@/components/FormField'
import { TextArea } from '@/components/TextArea/text_area'
import { Col, Row } from '@/components/Grid'
import Loader from '@/components/Loader'
import {
	ShareVideobotActions,
	ShareVideobotConfigWrapper,
	ShareVideobotPreviewWrapper,
	ShareVideobotSection,
} from '@/features/Videobot/components/ShareVideobot/ShareVideobot.styles'
import { useShareModal } from '@/features/Videobot/hooks/useShareModal'
import Error from '@/layout/Error'
import {
	BotStatusType,
	BotThumbnailType,
	FeatureType,
	SlideCategoryType,
} from '@/api/videobot.schemas'
import {
	getGetMediaByIdQueryKey,
	getMediaById,
	useReadBotById,
	useReadBotWidgetById,
} from '@/api/videobot'
import { useCurrentAccount } from '@/modules/session/auth.store'
import { useFeatureFlags } from '@/modules/session/global.hook'
import { useVideobotEditor } from '@/features/Videobot/hooks/videobot_editor'
import { ControlledFieldToggle } from '@/components/field_toggle/field_toggle.component'

import { VideobotIframe } from './VideobotIframe'
import { LANGUAGES, createEmbedUrl } from './sharedOptions'

const ALIGNS = [
	{
		id: 'bottom_right',
		label: 'videobot:sharePage.alignTypes.bottomRight',
	},
	{
		id: 'bottom_left',
		label: 'videobot:sharePage.alignTypes.bottomLeft',
	},
	{
		id: 'top_right',
		label: 'videobot:sharePage.alignTypes.topRight',
	},
	{
		id: 'top_left',
		label: 'videobot:sharePage.alignTypes.topLeft',
	},
]

const HIDE_TYPES = [
	{
		id: 'mobile',
		label: 'videobot:sharePage.hideTypes.mobile',
	},
	{
		id: 'both',
		label: 'videobot:sharePage.hideTypes.both',
	},
	{
		id: 'none',
		label: 'videobot:sharePage.hideTypes.none',
	},
]

const ShareVideobotWidgetPresentation = () => {
	const currentAccount = useCurrentAccount()
	const [t] = useTranslation(['videobot'])
	const {
		register,
		control,
		setValue,
		trigger,
		getValues,
		formState: { errors, isSubmitting },
		watch,
	} = useVideobotEditor()
	const { setModalContent, openModal } = useShareModal()

	const queryClient = useQueryClient()
	const activeAccount = useCurrentAccount()

	const videobot = watch()
	const watchSlides = watch('slides')

	const hasIntroSlide =
		Boolean(watchSlides?.length) &&
		watchSlides.some((slide) => slide.category === SlideCategoryType.INTRO)

	const widgetSettings = videobot.widgetSettings || {}

	// TODO: Rename to match current conventions
	const [chatVideo, setChatVideo] = useState(() => getValues('widgetSettings.chatVideo'))
	const [isChatVideoError, setIsChatVideoError] = useState(false)
	const chatVideoRef = useRef(getValues('widgetSettings.chatVideo'))
	const watchChatVideoId = watch('widgetSettings.chatVideoId')
	const watchThumbnailType = watch('widgetSettings.thumbnail.thumbnailType')

	const isAutoOpen = getValues('widgetSettings.automaticallyOpen')

	const { data: featureFlags } = useFeatureFlags({ account_id: videobot.accountId })

	const isWidgetSync = featureFlags?.includes(`dashboard:${FeatureType.widget_sync}`)
	const isNoCookies = featureFlags?.includes(`dashboard:${FeatureType.no_cookies}`)
	const isHideOnScroll = featureFlags?.includes(`dashboard:${FeatureType.hide_widget_on_scroll}`)
	const isCloseButtonEnabled = featureFlags?.includes(`dashboard:${FeatureType.close_button}`)
	const isVideobotSchedulerFlagEnabled = featureFlags?.includes(
		`dashboard:${FeatureType.videobot_scheduler}`,
	)

	const introSlide =
		videobot.slides.find((slide) => slide.category === SlideCategoryType.INTRO) ||
		videobot.slides[0]

	const defaultThumbnailSlide = widgetSettings.thumbnail?.slide ?? introSlide?.id
	const thumbnailSlide = videobot.slides?.find(
		(slide) => slide.id === widgetSettings.thumbnail?.slide,
	)

	const maxTimestamp = thumbnailSlide?.duration ?? introSlide?.duration

	const url = createEmbedUrl(videobot.publicId, {
		lang: widgetSettings.language,
	})

	const color = widgetSettings.borderColor
	const autoOpen = widgetSettings.automaticallyOpen && widgetSettings.openAfter
	const autoplayMuted = widgetSettings.autoplayMuted
	const label = widgetSettings.text
	const horizontalMode = widgetSettings.horizontalMode
	const loopLimit = widgetSettings.loopLimit || 15

	const codeLegacy = `
<div
	id="videobot"
	data-url="${url}"
	data-color="${color}"${autoOpen ? `\n    data-autoopen="${autoOpen}"` : ''}${
		isAutoOpen && autoplayMuted ? `\n    data-autoplay-muted="${autoplayMuted}"` : ''
	}${widgetSettings.ctaTextColor ? ` data-text-color="${widgetSettings.ctaTextColor}"` : ''}${
		widgetSettings.ctaBackgroundColor
			? ` data-background-color="${widgetSettings.ctaBackgroundColor}"`
			: ''
	}
	data-align="${widgetSettings.align}; x: ${getValues(
		'widgetSettings.horizontalOffset',
	)}; y: ${getValues('widgetSettings.verticalOffset')}"
	data-height="${getValues('widgetSettings.height')}"
	data-width="${getValues('widgetSettings.width')}"
	data-label="${label}"${horizontalMode ? `\n	data-horizontal="${horizontalMode}"` : ''}
	data-label-duration="${getValues('widgetSettings.textDurationMs')}"${
		widgetSettings.hideCtaMobile
			? ` data-hide-cta-mobile="${widgetSettings.hideCtaMobile}"`
			: ''
	}
	data-hide-on-scroll="${widgetSettings.hideOnScroll}"
	data-loop-limit="${loopLimit}" ${isNoCookies ? `\n	data-features="no_cookies"` : ''}
	${isHideOnScroll ? `data-features="hide_widget_on_scroll"` : ''}
	data-avatar-video="${widgetSettings.chatVideo?.url}"
	data-avatar-poster="${widgetSettings.chatVideo?.thumbnailUrl}?time=${widgetSettings.coverOffset}s"
></div>
<script type="module" src="${import.meta.env.VITE_EMBED_DOMAIN}videobot.mjs"></script>
`

	const codeSync = `
<div
	id="videobot"
	data-videobot-id="${videobot.publicId}"
	data-sync="true"
></div>
<script type="module" src="${import.meta.env.VITE_EMBED_DOMAIN}videobot.mjs"></script>
 `

	const embedCode = isWidgetSync ? codeSync : codeLegacy

	useEffect(() => {
		setModalContent({
			title: t('videobot:sharePage.embedWidget'),
			code: embedCode,
		})
	}, [embedCode])

	useEffect(() => {
		if (!watchChatVideoId) {
			return
		}

		const getChatVideo = async () => {
			const mediaData = await queryClient.fetchQuery({
				queryKey: getGetMediaByIdQueryKey(watchChatVideoId),
				queryFn: () => getMediaById(watchChatVideoId),
			})
			setChatVideo(mediaData)
			chatVideoRef.current = mediaData
		}

		setIsChatVideoError(false)
		getChatVideo()
	}, [watchChatVideoId, queryClient, setIsChatVideoError])

	const handleShowClick = async (event) => {
		event.preventDefault()
		if (!hasIntroSlide) {
			toast.warning(t('videobot:sharePage.noSlides'))
			return
		}
		if (!watchChatVideoId) {
			toast.warning(t('videobot:sharePage.noWidgetVideo'))
			setIsChatVideoError(true)
			return
		}
		openModal(true)
	}

	return (
		<React.Fragment>
			<ShareVideobotConfigWrapper>
				<div className="flex flex-1 flex-col overflow-auto">
					<ShareVideobotSection>
						<h4>{t('videobot:sharePage.appearance')}</h4>

						<Row>
							<Col>
								<FormField>
									<FieldAvatar
										name="widgetSettings.chatVideoId"
										type="video"
										mediaType="LogoVideo"
										valueName="widgetSettings.chatVideoId"
										setValue={setValue}
										accountId={activeAccount.id}
										error={isChatVideoError}
										defaultValue={chatVideo?.url}
									/>
								</FormField>
							</Col>
						</Row>

						<Row>
							<Col sm={6} xxs={12}>
								<FormField label={t('videobot:sharePage.coverOffset')}>
									<FieldText
										register={register}
										placeholder="0"
										name="widgetSettings.coverOffset"
										size="large"
										type="number"
										error={errors.widgetSettings?.coverOffset}
										defaultValue={widgetSettings?.coverOffset}
										rules={{
											valueAsNumber: true,
											min: {
												value: 0,
												message: t('validation:min', { value: 0 }),
											},
										}}
									/>
								</FormField>
							</Col>
						</Row>

						<Row>
							<Col sm={6} xxs={12}>
								<FormField label={t('videobot:sharePage.height')}>
									<FieldText
										register={register}
										placeholder="0"
										name="widgetSettings.height"
										size="large"
										type="number"
										error={errors.widgetSettings?.height}
										defaultValue={widgetSettings?.height}
										required
									/>
								</FormField>
							</Col>

							<Col sm={6} xxs={12}>
								<FormField label={t('videobot:sharePage.width')}>
									<FieldText
										register={register}
										placeholder="0"
										name="widgetSettings.width"
										type="number"
										error={errors.widgetSettings?.width}
										required
										size="large"
										defaultValue={widgetSettings?.width}
									/>
								</FormField>
							</Col>
						</Row>

						<Row>
							<Col>
								<FormField label={t('videobot:sharePage.align')}>
									<FieldSelect
										register={register}
										placeholder={ALIGNS[0].name}
										name="widgetSettings.align"
										type="text"
										error={errors.widgetSettings?.align}
										required
										options={ALIGNS.map((item) => ({
											value: item.id,
											label: t(item.label),
											item,
										}))}
										setValue={setValue}
										size="large"
										defaultValue={widgetSettings?.align}
									/>
								</FormField>
							</Col>
						</Row>

						<Row>
							<Col sm={6} xxs={12}>
								<FormField label={t('videobot:sharePage.verticalOffset')}>
									<FieldText
										register={register}
										placeholder="0"
										name="widgetSettings.verticalOffset"
										type="number"
										error={errors.widgetSettings?.verticalOffset}
										required
										size="large"
										defaultValue={widgetSettings?.verticalOffset || 0}
									/>
								</FormField>
							</Col>

							<Col sm={6} xxs={12}>
								<FormField label={t('videobot:sharePage.horizontalOffset')}>
									<FieldText
										register={register}
										placeholder="0"
										name="widgetSettings.horizontalOffset"
										type="number"
										error={errors.widgetSettings?.horizontalOffset}
										required
										size="large"
										defaultValue={widgetSettings?.horizontalOffset || 0}
									/>
								</FormField>
							</Col>
						</Row>

						<Row>
							<Col sm={6} xxs={12}>
								<FormField label={t('videobot:sharePage.loopLimit')}>
									<FieldText
										register={register}
										placeholder="0"
										name="widgetSettings.loopLimit"
										type="number"
										error={errors.widgetSettings?.loopLimit}
										defaultValue={widgetSettings?.loopLimit || 15}
										size="large"
									/>
								</FormField>
							</Col>
						</Row>

						<Row>
							<Col sm={6} xxs={12}>
								<FormField label={t('videobot:sharePage.borderColor')}>
									<FieldColor
										register={register}
										trigger={trigger}
										setValue={setValue}
										name="widgetSettings.borderColor"
										defaultValue={widgetSettings?.borderColor}
										required
									/>
								</FormField>
							</Col>
						</Row>

						<Row>
							<Col>
								<FormField label={t('videobot:sharePage.horizontalMode')}>
									<ControlledFieldToggle
										control={control}
										name="widgetSettings.horizontalMode"
										defaultSelected={!!widgetSettings?.horizontalMode}
									/>
								</FormField>
							</Col>
						</Row>
						<Row>
							<Col>
								<FormField label={t('videobot:sharePage.horizontalModeMobile')}>
									<ControlledFieldToggle
										control={control}
										name="widgetSettings.horizontalModeMobile"
										defaultSelected={!!widgetSettings?.horizontalModeMobile}
									/>
								</FormField>
							</Col>
						</Row>
					</ShareVideobotSection>
					<ShareVideobotSection>
						<h4>{t('videobot:sharePage.thumbnail')}</h4>

						<Row>
							<Col>
								<FormField label={t('videobot:sharePage.thumbnailType')}>
									<FieldSelect
										register={register}
										name="widgetSettings.thumbnail.thumbnailType"
										error={errors.widgetSettings?.thumbnail?.thumbnailType}
										options={Object.values(BotThumbnailType).map((item) => ({
											value: item,
											label: t(`videobot:sharePage.thumbnailTypes.${item}`),
											item,
										}))}
										setValue={setValue}
										defaultValue={
											videobot.widgetSettings?.thumbnail?.thumbnailType ||
											BotThumbnailType.FRAME
										}
										size="large"
									/>
								</FormField>
							</Col>
						</Row>

						{watchThumbnailType === BotThumbnailType.FRAME && (
							<Row>
								<Col sm={6} xxs={12}>
									<FormField label={t('videobot:sharePage.slide')}>
										<FieldSelect
											register={register}
											placeholder={introSlide?.name ?? ''}
											name="widgetSettings.thumbnail.slide"
											error={errors.widgetSettings?.thumbnail?.slide}
											options={videobot.slides.map((item) => ({
												value: item.id,
												label: item.name,
												item,
											}))}
											setValue={setValue}
											defaultValue={defaultThumbnailSlide}
											size="large"
										/>
									</FormField>
								</Col>

								<Col sm={6} xxs={12}>
									<FormField label={t('videobot:sharePage.timestamp')}>
										<FieldText
											register={register}
											placeholder="0"
											name="widgetSettings.thumbnail.time"
											type="number"
											size="large"
											error={errors.widgetSettings?.thumbnail?.time}
											defaultValue={widgetSettings?.thumbnail?.time || 0}
											rules={{
												valueAsNumber: true,
												min: {
													value: 0,
													message: t('validation:min', { value: 0 }),
												},
												max: {
													value: maxTimestamp,
													message: t('validation:max', {
														value: maxTimestamp,
													}),
												},
											}}
										/>
									</FormField>
								</Col>
							</Row>
						)}

						{watchThumbnailType === BotThumbnailType.CUSTOM && (
							<Row>
								<Col>
									<FieldAvatar
										name="widgetThumbnailId"
										type="image"
										mediaType="CoverImage"
										valueName="widgetThumbnailId"
										setValue={setValue}
										defaultValue={videobot.widgetThumbnail?.url}
										accountId={currentAccount.id}
										label={t('videobot:sharePage.customThumbnail')}
										isCover
									/>
								</Col>
							</Row>
						)}
					</ShareVideobotSection>
					<ShareVideobotSection>
						<h4>{t('videobot:sharePage.content')}</h4>
						<Row>
							<Col>
								<FormField label={t('videobot:sharePage.language')}>
									<FieldSelect
										register={register}
										placeholder={LANGUAGES[0].name}
										name="widgetSettings.language"
										type="text"
										error={errors.widgetSettings?.language}
										required
										options={LANGUAGES.map((item) => ({
											value: item.id,
											label: item.name,
											item,
										}))}
										setValue={setValue}
										size="large"
										defaultValue={widgetSettings?.language}
									/>
								</FormField>
							</Col>
						</Row>

						<Row>
							<Col>
								<FormField label={t('videobot:sharePage.text')}>
									<FieldText
										register={register}
										placeholder="See how it works!"
										name="widgetSettings.text"
										type="text"
										setValue={setValue}
										error={errors.widgetSettings?.text}
										defaultValue={widgetSettings?.text}
										size="large"
									/>
								</FormField>
							</Col>
						</Row>
						<Row>
							<Col sm={6} xxs={12}>
								<FormField label={t('videobot:sharePage.textDuration')}>
									<FieldText
										register={register}
										placeholder="10000"
										name="widgetSettings.textDurationMs"
										type="number"
										size="large"
										error={errors.widgetSettings?.textDurationMs}
										defaultValue={widgetSettings?.textDurationMs || 10000}
										rules={{ valueAsNumber: true }}
									/>
								</FormField>
							</Col>
						</Row>
						<Row>
							<Col sm={6} xxs={12}>
								<FormField label={t('videobot:sharePage.ctaTextColor')}>
									<FieldColor
										register={register}
										trigger={trigger}
										setValue={setValue}
										name="widgetSettings.ctaTextColor"
										defaultValue={widgetSettings?.ctaTextColor}
										required
									/>
								</FormField>
							</Col>
							<Col sm={6} xxs={12}>
								<FormField label={t('videobot:sharePage.ctaBackgroundColor')}>
									<FieldColor
										register={register}
										trigger={trigger}
										setValue={setValue}
										name="widgetSettings.ctaBackgroundColor"
										defaultValue={widgetSettings?.ctaBackgroundColor}
										required
									/>
								</FormField>
							</Col>
						</Row>
						<Row>
							<Col>
								<FormField label={t('videobot:sharePage.hideCtaMobile')}>
									<ControlledFieldToggle
										control={control}
										name="widgetSettings.hideCtaMobile"
										defaultSelected={!!widgetSettings?.hideCtaMobile}
									/>
								</FormField>
							</Col>
						</Row>
					</ShareVideobotSection>
					<ShareVideobotSection>
						<h4>{t('videobot:sharePage.functionality')}</h4>

						{isHideOnScroll && (
							<Row>
								<Col>
									<FormField label={t('videobot:sharePage.hideOnScroll')}>
										<FieldSelect
											register={register}
											placeholder={t(HIDE_TYPES[0].label)}
											name="widgetSettings.hideOnScroll"
											type="text"
											error={errors.widgetSettings?.hideOnScroll}
											defaultValue={widgetSettings?.hideOnScroll}
											options={HIDE_TYPES.map((item) => ({
												value: item.id,
												label: t(item.label),
												item,
											}))}
											setValue={setValue}
											size="large"
										/>
									</FormField>
								</Col>
							</Row>
						)}

						<Row>
							<Col>
								<FormField label={t('videobot:sharePage.automaticallyOpen')}>
									<ControlledFieldToggle
										control={control}
										name="widgetSettings.automaticallyOpen"
										defaultSelected={!!widgetSettings?.automaticallyOpen}
									/>
								</FormField>
							</Col>
						</Row>

						{isAutoOpen && (
							<FormField label={t('videobot:sharePage.openAfter')}>
								<FieldText
									register={register}
									placeholder="3000"
									name="widgetSettings.openAfter"
									type="number"
									error={errors.widgetSettings?.openAfter}
									defaultValue={widgetSettings?.openAfter}
									setValue={setValue}
									size="large"
								/>
							</FormField>
						)}
						{isAutoOpen && (
							<Row>
								<Col>
									<FormField label={t('videobot:sharePage.autoplayMuted')}>
										<ControlledFieldToggle
											control={control}
											name="widgetSettings.autoplayMuted"
											defaultSelected={!!widgetSettings?.autoplayMuted}
										/>
									</FormField>
								</Col>
							</Row>
						)}

						{isCloseButtonEnabled && (
							<Row>
								<Col>
									<FormField label={t('videobot:sharePage.closeButtonEnabled')}>
										<ControlledFieldToggle
											control={control}
											name="widgetSettings.closeButtonEnabled"
											defaultSelected={!!widgetSettings?.closeButtonEnabled}
										/>
									</FormField>
								</Col>
							</Row>
						)}
					</ShareVideobotSection>
					<ShareVideobotSection>
						{isVideobotSchedulerFlagEnabled && (
							<React.Fragment>
								<h4>{t('videobot:sharePage.videobotScheduler')}</h4>
								<Row>
									<Col>
										<FormField
											label={t(
												'videobot:sharePage.videobotSchedulerDescription',
											)}
										>
											<ControlledFieldToggle
												control={control}
												name="widgetSettings.videobotScheduler.enabled"
												defaultSelected={
													!!widgetSettings?.videobotScheduler?.enabled
												}
											/>
										</FormField>
									</Col>
								</Row>
								{widgetSettings?.videobotScheduler?.enabled && (
									<React.Fragment>
										<FormField label={t('account:fields.startDate.label')}>
											<Controller
												name="widgetSettings.videobotScheduler.startTime"
												control={control}
												render={({ field }) => (
													<DatePicker
														{...field}
														format="yyyy-MM-dd HH:mm"
														placement="topStart"
														style={{ width: 220 }}
														onChange={(value) => field.onChange(value)}
														value={new Date(field.value)}
														defaultValue={
															widgetSettings?.videobotScheduler
																?.startTime
																? new Date(
																		widgetSettings?.videobotScheduler?.startTime,
																	)
																: null
														}
													/>
												)}
											/>
										</FormField>
										<FormField label={t('account:fields.endDate.label')}>
											<Controller
												name="widgetSettings.videobotScheduler.endTime"
												control={control}
												render={({ field }) => (
													<DatePicker
														{...field}
														format="yyyy-MM-dd HH:mm"
														placement="topStart"
														style={{ width: 220 }}
														onChange={(value) => field.onChange(value)}
														value={new Date(field.value)}
														defaultValue={
															widgetSettings?.videobotScheduler
																?.endTime
																? new Date(
																		widgetSettings?.videobotScheduler?.endTime,
																	)
																: null
														}
													/>
												)}
											/>
										</FormField>
									</React.Fragment>
								)}
							</React.Fragment>
						)}
					</ShareVideobotSection>
					<ShareVideobotSection>
						<React.Fragment>
							<h4>{t('videobot:sharePage.customCss.title')}</h4>
							<div>
								<TextArea
									rows={8}
									placeholder={t('videobot:sharePage.customCss.placeholder')}
									{...register('widgetSettings.stylesRaw')}
								/>
							</div>
						</React.Fragment>
					</ShareVideobotSection>
				</div>

				<ShareVideobotActions>
					<Button
						onClick={handleShowClick}
						disabled={videobot.status !== BotStatusType.Published || isSubmitting}
						iconPrepend="code"
					>
						{t('videobot:sharePage.showCode')}
					</Button>
				</ShareVideobotActions>
			</ShareVideobotConfigWrapper>

			<ShareVideobotPreviewWrapper>
				<VideobotIframe
					src={`/widget-preview/current/?embed=true`}
					data={{ ...widgetSettings, chatVideo }}
					videobot={videobot}
					variant="cover"
					style={{ width: '100%', height: '100%' }}
				/>
			</ShareVideobotPreviewWrapper>
		</React.Fragment>
	)
}

export const ShareVideobotWidget = () => {
	const { id } = useParams()
	const { data: videobot, isError: isErrorVideobot } = useReadBotById(id)
	const { data: videobotEmbed, isError: isErrorVideobotEmbed } = useReadBotWidgetById(id)

	const isLoading = !videobot || !videobotEmbed
	const isError = isErrorVideobot || isErrorVideobotEmbed

	return (
		<React.Fragment>
			<ShareVideobotWidgetPresentation />
			{isLoading && <Loader cover isLoading />}
			{isError && <Error background="body" />}
		</React.Fragment>
	)
}
