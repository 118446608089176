import React, { useEffect } from 'react'
import { isNil } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'

import Button from '@/components/Button'
import FieldSelect from '@/components/FieldSelect'
import FormField from '@/components/FormField'
import { Col, Row } from '@/components/Grid'
import Loader from '@/components/Loader'
import {
	ShareVideobotActions,
	ShareVideobotConfigWrapper,
	ShareVideobotIframePreviewWrapper,
	ShareVideobotSection,
} from '@/features/Videobot/components/ShareVideobot/ShareVideobot.styles'
import { useShareModal } from '@/features/Videobot/hooks/useShareModal'
import Error from '@/layout/Error'
import { BotStatusType, BotThumbnailType, SlideCategoryType } from '@/api/videobot.schemas'
import FieldText from '@/components/FieldText'
import { useReadBotById, useReadBotWidgetById } from '@/api/videobot'
import { useVideobotEditor } from '@/features/Videobot/hooks/videobot_editor'
import { ControlledFieldToggle } from '@/components/field_toggle/field_toggle.component'
import FieldAvatar from '@/components/FieldAvatar'
import { useCurrentAccount } from '@/modules/session/auth.store'

import { VideobotIframe } from './VideobotIframe'
import { LANGUAGES, createEmbedUrl } from './sharedOptions'

const ShareVideobotLinkPresentation = () => {
	const currentAccount = useCurrentAccount()
	const [t] = useTranslation(['videobot'])
	const {
		register,
		setValue,
		control,
		formState: { errors, isSubmitting },
		watch,
		getValues,
	} = useVideobotEditor()
	const { setModalContent, openModal } = useShareModal()

	const videobot = getValues()
	const watchSlides = watch('slides')
	const watchSlideId = watch('slide')
	const watchThumbnailType = watch('linkSettings.thumbnail.thumbnailType')

	const hasIntroSlide =
		Boolean(watchSlides?.length) &&
		watchSlides.some((slide) => slide.category === SlideCategoryType.INTRO)

	const introSlide =
		videobot.slides?.find((slide) => slide.category === SlideCategoryType.INTRO) ||
		videobot.slides?.[0]

	const defaultSlideId = videobot.linkSettings?.slideId ?? introSlide?.id
	const defaultThumbnailSlide = videobot.linkSettings?.thumbnail?.slide ?? introSlide?.id

	const thumbnailSlide = videobot.slides?.find(
		(slide) => slide.id === videobot.linkSettings.thumbnail?.slide,
	)

	const maxTimestamp = thumbnailSlide?.duration ?? introSlide?.duration ?? 0

	const lang = videobot.linkSettings?.language || 'en'
	const slideId =
		!isNil(videobot.linkSettings.slideId) && videobot.linkSettings.slideId !== introSlide?.id
			? videobot.linkSettings.slideId
			: null

	const url = createEmbedUrl(videobot.publicId, {
		lang,
		slide_id: slideId,
	})

	useEffect(() => {
		setModalContent({
			title: t('videobot:sharePage.embedLink'),
			element: 'input',
			code: url,
		})
	}, [url])

	const handleShowClick = async (event) => {
		event.preventDefault()
		if (!hasIntroSlide) {
			toast.warning(t('videobot:sharePage.noSlides'))
			return
		}
		openModal(true)
	}

	return (
		<React.Fragment>
			<ShareVideobotConfigWrapper>
				<div className="flex flex-1 flex-col overflow-auto">
					<ShareVideobotSection>
						<h4>{t('videobot:sharePage.options')}</h4>

						<Row>
							<Col>
								<FormField label={t('videobot:sharePage.language')}>
									<FieldSelect
										register={register}
										placeholder={LANGUAGES[0].name}
										name="linkSettings.language"
										type="text"
										error={errors.linkSettings?.language}
										required
										options={LANGUAGES.map((item) => ({
											value: item.id,
											label: item.name,
											item,
										}))}
										setValue={setValue}
										size="large"
										defaultValue={videobot.linkSettings?.language || 'en'}
									/>
								</FormField>
							</Col>
						</Row>

						<Row>
							<Col>
								<FormField label={t('videobot:sharePage.slide')}>
									<FieldSelect
										register={register}
										placeholder={introSlide?.name ?? ''}
										name="linkSettings.slideId"
										type="text"
										error={errors.linkSettings?.slideId}
										options={videobot.slides?.map((item) => ({
											value: item.id,
											label: item.name,
											item,
										}))}
										setValue={setValue}
										defaultValue={defaultSlideId}
										size="large"
									/>
								</FormField>
							</Col>
						</Row>
					</ShareVideobotSection>

					<ShareVideobotSection>
						<h4>{t('videobot:sharePage.thumbnail')}</h4>

						<Row>
							<Col>
								<FormField label={t('videobot:sharePage.thumbnailType')}>
									<FieldSelect
										register={register}
										name="linkSettings.thumbnail.thumbnailType"
										error={errors.linkSettings?.thumbnail?.thumbnailType}
										options={Object.values(BotThumbnailType).map((item) => ({
											value: item,
											label: t(`videobot:sharePage.thumbnailTypes.${item}`),
											item,
										}))}
										setValue={setValue}
										defaultValue={
											videobot.linkSettings?.thumbnail?.thumbnailType ||
											BotThumbnailType.FRAME
										}
										size="large"
									/>
								</FormField>
							</Col>
						</Row>

						{watchThumbnailType === BotThumbnailType.FRAME && (
							<Row>
								<Col sm={6} xxs={12}>
									<FormField label={t('videobot:sharePage.slide')}>
										<FieldSelect
											register={register}
											placeholder={introSlide?.name ?? ''}
											name="linkSettings.thumbnail.slide"
											error={errors.linkSettings?.thumbnail?.slide}
											options={videobot.slides?.map((item) => ({
												value: item.id,
												label: item.name,
												item,
											}))}
											setValue={setValue}
											defaultValue={defaultThumbnailSlide}
											size="large"
										/>
									</FormField>
								</Col>

								<Col sm={6} xxs={12}>
									<FormField label={t('videobot:sharePage.timestamp')}>
										<FieldText
											register={register}
											placeholder="0"
											name="linkSettings.thumbnail.time"
											type="number"
											error={errors.linkSettings?.thumbnail?.time}
											size="large"
											defaultValue={
												videobot.linkSettings?.thumbnail?.time || 0
											}
											rules={{
												valueAsNumber: true,
												min: {
													value: 0,
													message: t('validation:min', { value: 0 }),
												},
												max: {
													value: maxTimestamp,
													message: t('validation:max', {
														value: maxTimestamp,
													}),
												},
											}}
										/>
									</FormField>
								</Col>
							</Row>
						)}

						{watchThumbnailType === BotThumbnailType.CUSTOM && (
							<Row>
								<Col>
									<FieldAvatar
										name="linkThumbnailId"
										type="image"
										mediaType="CoverImage"
										valueName="linkThumbnailId"
										setValue={setValue}
										defaultValue={videobot.linkThumbnail?.url}
										accountId={currentAccount.id}
										label={t('videobot:sharePage.customThumbnail')}
										isCover
									/>
								</Col>
							</Row>
						)}
					</ShareVideobotSection>
					<ShareVideobotSection>
						<h4>{t('videobot:sharePage.functionality')}</h4>
						<Row>
							<Col>
								<FormField label={t('videobot:sharePage.autoplayMuted')}>
									<ControlledFieldToggle
										control={control}
										name="linkSettings.autoplayMuted"
										defaultSelected={!!videobot.linkSettings?.autoplayMuted}
									/>
								</FormField>
							</Col>
						</Row>
					</ShareVideobotSection>
				</div>

				<ShareVideobotActions>
					<Button
						onClick={handleShowClick}
						disabled={videobot.status !== BotStatusType.Published || isSubmitting}
						iconPrepend="code"
					>
						{t('videobot:sharePage.showLink')}
					</Button>
				</ShareVideobotActions>
			</ShareVideobotConfigWrapper>

			<ShareVideobotIframePreviewWrapper>
				<VideobotIframe
					src={`/preview/current?preview=true${
						watchSlideId ? `&slide_id=${watchSlideId}` : ''
					}`}
					videobot={videobot}
					variant="cover"
				/>
			</ShareVideobotIframePreviewWrapper>
		</React.Fragment>
	)
}

export const ShareVideobotLink = () => {
	const { id } = useParams()
	const { data: videobot, isError: isErrorVideobot } = useReadBotById(id)
	const { data: videobotEmbed, isError: isErrorVideobotEmbed } = useReadBotWidgetById(id)

	const isLoading = !videobot || !videobotEmbed
	const isError = isErrorVideobot || isErrorVideobotEmbed

	return (
		<React.Fragment>
			<ShareVideobotLinkPresentation />
			{isLoading && <Loader cover isLoading />}
			{isError && <Error background="body" />}
		</React.Fragment>
	)
}
