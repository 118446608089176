import { useEffect, type PropsWithChildren } from 'react'
import { Navigate, useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useAuthenticator } from '@aws-amplify/ui-react'

import { defaultLanguage } from '@/lib/i18n'
import { useReadCurrentUserAccounts, useUsersCurrentUser } from '@/api/videobot'
import Loader from '@/components/Loader'
import {
	CurrentAccountContext,
	CurrentUserContext,
	ON_LOGIN_EMAIL_KEY,
	isInvalidUser,
	useAuth,
	useCurrentAccount,
	useCurrentUser,
} from '@/modules/session/auth.store'
import { useFeatureFlags } from '@/modules/session/global.hook'
import { RoleType } from '@/api/videobot.schemas'
import Error from '@/layout/Error'
import Button from '@/components/Button'

// Permission check
export const AuthCheck = ({ children }: PropsWithChildren) => {
	const { isAuthenticated, logout } = useAuth()
	const { user } = useAuthenticator((context) => [context.user])
	const [t] = useTranslation()
	const AnyButton = Button as any
	const navigate = useNavigate()

	if (!isAuthenticated) {
		return <Navigate to="/login" replace />
	}

	const onLoginEmail = sessionStorage.getItem(ON_LOGIN_EMAIL_KEY)
	const logoutHandler = async () => {
		await logout()
		navigate('/login')
	}
	if (onLoginEmail && onLoginEmail !== user.attributes?.email) {
		const actions = (
			<div>
				<AnyButton onClick={logoutHandler}>Log Out</AnyButton>
			</div>
		)
		return (
			<Error
				title={t(
					'[Error] Emails mismatch, please login with the same email as you got the invitation to.',
				)}
				actions={actions}
			/>
		)
	}

	return children
}

export const UnauthCheck = ({ children }: PropsWithChildren) => {
	const { isAuthenticated } = useAuth()

	if (isAuthenticated) {
		return <Navigate to="/dashboard" replace />
	}

	return children
}

export const RoleCheck = ({
	children,
	roles,
	skipAccountRole,
}: PropsWithChildren<{ roles: RoleType[]; skipAccountRole?: boolean }>) => {
	const currentUser = useCurrentUser()
	const currentAccount = useCurrentAccount()

	// Super Admin rules!
	if (currentUser.isSuperuser) {
		return children
	}

	// Reseller only skip account check if skipAccountRole is true
	if (roles.includes(RoleType.Reseller) && currentUser.isReseller && skipAccountRole) {
		return children
	}

	if (!currentAccount) {
		return <Navigate to="/dashboard" replace />
	}

	if (roles.includes(currentAccount.role)) {
		return children
	}

	return <Navigate to="/dashboard" replace />
}

// Startup setup on authenticated
const SetupUser = ({ children }: PropsWithChildren) => {
	const currentUser = useCurrentUser()
	const { i18n } = useTranslation()

	useEffect(() => {
		const language = currentUser.language ?? defaultLanguage
		i18n.changeLanguage(language)
	}, [currentUser, i18n])

	return children
}

const GlobalDataStartup = ({ children }: PropsWithChildren) => {
	const { activeAccountId, isAuthenticated } = useAuth()
	const { data: featureFlagsData } = useFeatureFlags({
		account_id: activeAccountId,
	})
	const { data: currentUserAccountsData } = useReadCurrentUserAccounts({
		query: { enabled: isAuthenticated },
	})

	// FIXME: This is a hack to work around plain JS
	const AnyLoader = Loader as any
	// FIXME: This is a hack to work around videobot preview
	const urlSearchParams = new URLSearchParams(window.location.search)
	const preview = urlSearchParams.get('preview')
	const embed = urlSearchParams.get('embed')
	const isInIframe = preview || embed

	if (!isAuthenticated || isInIframe) {
		return children
	}

	if (!featureFlagsData || !currentUserAccountsData) {
		return <AnyLoader cover />
	}

	const currentAccount = activeAccountId
		? currentUserAccountsData.find((account) => account.id === activeAccountId) || null
		: null

	return (
		<CurrentAccountContext.Provider value={currentAccount}>
			<SetupUser>{children}</SetupUser>
		</CurrentAccountContext.Provider>
	)
}

export const AuthenticatedStartup = ({ children }: PropsWithChildren) => {
	const { isAuthenticated } = useAuth()
	const { data: currentUserData, error } = useUsersCurrentUser({
		query: { enabled: isAuthenticated },
	})

	// FIXME: This is a hack to work around plain JS
	const AnyLoader = Loader as any
	// FIXME: This is a hack to work around videobot preview
	const urlSearchParams = new URLSearchParams(window.location.search)
	const preview = urlSearchParams.get('preview')
	const embed = urlSearchParams.get('embed')
	const isInIframe = preview || embed

	if (!isAuthenticated || isInIframe) {
		return children
	}

	if (error && isInvalidUser(error)) {
		return children
	}

	if (!currentUserData) {
		return <AnyLoader cover />
	}

	return (
		<CurrentUserContext.Provider value={currentUserData}>
			<GlobalDataStartup>
				<SetupUser>{children}</SetupUser>
			</GlobalDataStartup>
		</CurrentUserContext.Provider>
	)
}
