import React, { useEffect, useRef, useState } from 'react'
import ky from 'ky'
import { AnimatePresence } from 'framer-motion'
import Hls from 'hls.js'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { VIDEO_MIMETYPE_SUPPORT } from '@/lib/constants'
import PropTypes from '@/lib/propTypes'
import { useCreateMedia, useGetMediaById } from '@/api/videobot'
import { MediaStatusType } from '@/api/videobot.schemas'

import Button from '../Button'
import Cropper from '../Cropper'
import Loader from '../Loader'
import {
	FieldAvatarActions,
	FieldAvatarInput,
	FieldAvatarPreview,
	FieldAvatarWrapper,
} from './FieldAvatar.styles'

const FieldAvatar = ({
	error,
	defaultValue,
	type = 'image',
	valueName,
	setValue,
	mediaType = 'LogoImage',
	accountId,
	label,
	aspectRatio = 1,
	isCover = false,
}) => {
	const { t } = useTranslation(['validation'])
	const inputRef = useRef(null)
	const videoRef = useRef(null)
	const timeoutRef = useRef(null)
	const [preview, setPreview] = useState(defaultValue)
	const [showCropper, setShowCropper] = useState(false)
	const [file, setFile] = useState(null)
	const [filePreview, setFilePreview] = useState(null)
	const acceptedTypes = type === 'video' ? VIDEO_MIMETYPE_SUPPORT : 'image/png, image/jpeg'
	const [checkingMediaId, setCheckingMediaId] = useState(null)
	const { data } = useGetMediaById(checkingMediaId, {
		query: { enabled: Boolean(checkingMediaId), refetchInterval: 2000 },
	})
	const { mutateAsync, isLoading: isMediaCreating } = useCreateMedia()
	const [isCDNUploading, setIsCDNUploading] = useState(false)

	const isLoading = Boolean(checkingMediaId) || isMediaCreating || isCDNUploading

	useEffect(() => {
		if (!checkingMediaId) {
			return
		}
		if (!data) {
			return
		}
		if (data.status === MediaStatusType.Ready) {
			setPreview(data.url)
			setValue(valueName, data.id, { shouldValidate: true, shouldDirty: true })
			setCheckingMediaId(null)
		}
	}, [data, checkingMediaId, setValue, valueName])

	const handleUploadClick = (e) => {
		inputRef.current.value = null
		inputRef.current.click()
		e.preventDefault()
	}

	const handleRemoveClick = (e) => {
		setPreview(null)
		e.preventDefault()
		setValue(valueName, null, { shouldValidate: true, shouldDirty: true })
	}

	const checkVideoStatus = (mediaId) => {
		setCheckingMediaId(mediaId)
	}

	const handleSave = async (file, _data) => {
		setShowCropper(false)
		// FIXME: Maybe need some better way to check if field is uploading
		setValue(valueName, null, { shouldValidate: true, shouldDirty: true })

		try {
			const data = await mutateAsync({
				data: { type: mediaType, name: file.name, accountId },
			})
			const mediaId = data.id
			const url = data.uploadUrl.uploadUrl
			const formData = new FormData()
			formData.append('file', file, {
				type: file.type,
			})

			try {
				setIsCDNUploading(true)
				const cdnMedia = await ky.post(url, { body: formData, timeout: 5 * 60000 }).json()
				if (type === 'image') {
					setPreview(cdnMedia.result?.variants[0])
					setValue(valueName, mediaId, {
						shouldValidate: true,
						shouldDirty: true,
					})
				} else if (type === 'video') {
					checkVideoStatus(mediaId)
				}
				setIsCDNUploading(false)
			} catch (e) {
				toast.error(t('errors:errorOccured'))
				throw e
			}
		} catch (e) {
			toast.error(t('errors:errorOccured'))
			throw e
		}
	}

	const handleChange = (e) => {
		const file = e.target.files[0]

		if (!file.type.startsWith(`${type}/`)) return

		const fileReader = new FileReader()

		const readFile = () => {
			setFilePreview(fileReader.result?.toString())
		}

		fileReader.addEventListener('load', readFile)
		fileReader.readAsDataURL(file)

		if (type === 'image') {
			if (isCover) {
				handleSave(file, file, URL.createObjectURL(file))
			} else {
				setShowCropper(true)
			}
		} else if (type === 'video') {
			handleSave(file, file, URL.createObjectURL(file))
		}

		setFile(file)
	}

	useEffect(() => {
		if (type === 'video' && preview) {
			const video = videoRef.current

			if (Hls.isSupported()) {
				const hls = new Hls()
				hls.loadSource(preview)
				hls.attachMedia(videoRef.current)
			} else if (video.canPlayType('application/vnd.apple.mpegurl')) {
				video.src = preview
			}
		}
	}, [preview, type])

	useEffect(() => {
		return () => {
			clearTimeout(timeoutRef.current)
		}
	}, [])

	useEffect(() => {
		if (!showCropper) {
			setFilePreview(null)
		}
	}, [showCropper])

	return (
		<React.Fragment>
			<FieldAvatarWrapper error={error}>
				<FieldAvatarPreview isCover={isCover}>
					{(type === 'image' || !preview) && (
						<img src={preview || '/img/default-thumbnail.png'} alt="" />
					)}
					{type === 'video' && preview && (
						<video muted autoPlay loop ref={videoRef} playsInline />
					)}
					{isLoading && <Loader cover size={32} />}
				</FieldAvatarPreview>
				<FieldAvatarInput
					ref={inputRef}
					type="file"
					onChange={handleChange}
					accept={acceptedTypes}
				></FieldAvatarInput>
				<FieldAvatarActions>
					<Button
						size="small"
						variant="secondary"
						disabled={isLoading}
						onClick={handleUploadClick}
					>
						{t('upload')}
					</Button>
					<Button
						size="small"
						variant="secondary"
						disabled={isLoading}
						onClick={handleRemoveClick}
					>
						{t('remove')}
					</Button>
				</FieldAvatarActions>
			</FieldAvatarWrapper>
			<AnimatePresence>
				{showCropper && filePreview && type === 'image' && (
					<Cropper
						file={file}
						title={label}
						src={filePreview}
						onSave={handleSave}
						onCancel={() => setShowCropper(false)}
						aspect={aspectRatio}
					/>
				)}
			</AnimatePresence>
		</React.Fragment>
	)
}

FieldAvatar.displayName = 'FieldAvatar'

FieldAvatar.propTypes = {
	error: PropTypes.bool,
	defaultValue: PropTypes.string,
	type: PropTypes.oneOf(['image', 'video']),
	valueName: PropTypes.string,
	label: PropTypes.string,
	setValue: PropTypes.func,
	mediaType: PropTypes.oneOf(['LogoImage', 'LogoVideo', 'CoverImage']),
	accountId: PropTypes.number,
	aspectRatio: PropTypes.number,
}

export default FieldAvatar
