import { AnimatePresence } from 'framer-motion'
import { clamp } from 'lodash-es'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import CheckPicker from 'rsuite/esm/CheckPicker'

import Button from '@/components/Button'
import Loader from '@/components/Loader'
import { useAuth, useCurrentAccount } from '@/modules/session/auth.store'
import CreateVideobotModal from '@/features/Videobot/components/CreateVideobotModal/CreateVideobotModal'
import VideobotsList from '@/features/Videobot/components/VideobotsList'
import Error from '@/layout/Error'
import { useReadAllBots, useReadAllTags } from '@/api/videobot'
import FieldText from '@/components/FieldText'
import { RoleCheck } from '@/modules/session/auth.component'
import { RoleType } from '@/api/videobot.schemas'

import View from '../../layout/View'

const INITIAL_PARAMS = { order_by: 'id', order_direction: 'desc', page: 1 }

const AllVideobotsContents = () => {
	const [searchParams, setSearchParams] = useSearchParams(INITIAL_PARAMS)
	const { register, handleSubmit } = useForm()
	const page = Number(searchParams.get('page'))
	const orderBy = searchParams.get('order_by')
	const orderDirection = searchParams.get('order_direction')
	const botName = searchParams.get('bot_name')
	const currentAccount = useCurrentAccount()
	const { data: tags } = useReadAllTags({ account_id: currentAccount?.id })
	const [tagFilter, setTagFilter] = useState([])
	const isViewer = currentAccount?.role === 'Viewer'

	const size = 10

	const { t } = useTranslation(['videobot'])
	const [showCreateModal, setShowCreateModal] = useState(false)

	const handleCreateNewClick = () => {
		setShowCreateModal(true)
	}

	const { activeAccountId } = useAuth()

	const { data, error, isFetching, refetch } = useReadAllBots(
		{
			account_id: activeAccountId,
			size,
			page,
			order_direction: orderDirection,
			order_by: orderBy,
			bot_name: botName,
			tag_ids: tagFilter.length ? tagFilter.join(',') : null,
		},
		{ query: { keepPreviousData: true } },
	)

	const onPageChange = useCallback(
		(newPage) => {
			if (!data) {
				return
			}
			const params = {
				order_by: orderBy,
				order_direction: orderDirection,
				page: clamp(newPage, 1, data.metadata.pageCount),
			}
			setSearchParams((prev) => ({ ...Object.fromEntries(prev), ...params }))
		},
		[setSearchParams, data],
	)

	const onSort = useCallback(
		(selectedColumn, sortDirection) => {
			const params = {
				order_by: selectedColumn?.id,
				order_direction: sortDirection,
				page: 1,
			}

			setSearchParams((prev) => ({
				...Object.fromEntries(prev),
				order_by: selectedColumn?.id,
				order_direction: sortDirection,
				page,
			}))

			setTimeout(() => {
				setSearchParams((prev) => ({ ...Object.fromEntries(prev), ...params }))
			}, 100)
		},
		[setSearchParams],
	)

	useEffect(() => {
		// fetching new data here based on botName and other searchParams if necessary
		refetch({
			account_id: activeAccountId,
			size,
			page: Number(searchParams.get('page')),
			order_direction: searchParams.get('order_direction'),
			order_by: searchParams.get('order_by'),
			bot_name: searchParams.get('bot_name'),
		})
	}, [searchParams])

	const onSearch = (data) => {
		setSearchParams((prev) => {
			if (data.bot_name) {
				prev.set('bot_name', data.bot_name)
			} else {
				prev.delete('bot_name')
			}
			prev.set('page', 1)
			return prev
		})

		refetch({
			account_id: activeAccountId,
			size,
			page: Number(searchParams.get('page')),
			order_direction: searchParams.get('order_direction'),
			order_by: searchParams.get('order_by'),
			bot_name: searchParams.get('bot_name'),
		})
	}

	if (error) {
		return <Error error={error} />
	}

	if (data) {
		const bots = data.records
		return (
			<Fragment>
				<View
					header
					title={t('videobots')}
					actions={
						<Button onClick={handleCreateNewClick} disabled={isViewer}>
							{t('videobot:createNew')}
						</Button>
					}
					mobileTopBar
					isLoading={isFetching}
				>
					<div className="mb-4 flex flex-col items-start gap-4 md:flex-row md:items-end">
						<form
							onSubmit={handleSubmit(onSearch)}
							className="flex w-full gap-4 md:w-128"
						>
							<label className="flex flex-col gap-2">
								<span className="select-none opacity-75">Search by name</span>
								<div className="flex gap-2">
									<FieldText
										type="text"
										className="rounded"
										placeholder="Search bots..."
										name="bot_name"
										register={register}
									/>
									<button
										className="rounded bg-primary px-8 text-body transition hover:bg-dark"
										type="submit"
									>
										Search
									</button>
								</div>
							</label>
						</form>

						{tags && (
							<div className="mt-4 flex w-full flex-col">
								<span className="mb-2 select-none opacity-75">
									{t('videobot:filterByTags')}
								</span>
								<div style={{ width: '100%' }}>
									<CheckPicker
										data={tags.records.map((tag) => ({
											label: tag.name,
											value: tag.id,
										}))}
										className="custom-checkpicker"
										value={tagFilter}
										onChange={(selectedValues) => {
											setTagFilter(selectedValues)
										}}
										style={{
											width: 224,
											height: 'auto',
										}}
										placeholder={t('videobot:selectTags')}
									/>
								</div>
							</div>
						)}
					</div>
					<VideobotsList
						videobots={bots}
						pageSize={size}
						totalCount={data.metadata.totalCount}
						onPageChange={onPageChange}
						page={data.metadata.page}
						onSort={onSort}
						defaultSortFieldId={orderBy || 'name'}
						defaultSortAsc={orderDirection === 'asc'}
						sortServer
					/>
				</View>
				<AnimatePresence>
					{showCreateModal && (
						<CreateVideobotModal onClose={() => setShowCreateModal(false)} />
					)}
				</AnimatePresence>
			</Fragment>
		)
	}

	return <Loader cover />
}

const AllVideobots = () => {
	return (
		<RoleCheck
			roles={[
				RoleType.Admin,
				RoleType.Editor,
				RoleType.Viewer,
				RoleType.Owner,
				RoleType.Reseller,
				RoleType.Super_Admin,
			]}
		>
			<AllVideobotsContents />
		</RoleCheck>
	)
}

export default AllVideobots
